import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    id: String
  };

  connect() {
    const controller = this;

    const observer = new IntersectionObserver(
      function(element) {
        if(element[0].isIntersecting === true) {
          controller.trackFormViewed();
        }
      },
      { threshold: [1] }
    );

    observer.observe(this.element);
  }

  trackFormViewed() {
    if (this.formViewed) { return }

    this.formViewed = true;
    window.analytics.track(
      "Mailing List Form Seen",
      {
        promptId: this.idValue
      }
    );
  }

  trackFormSubmitted() {
    if (this.formSubmitted) { return }

    this.formSubmitted = true;
    window.analytics.track(
      "Mailing List Form Submitted",
      {
        promptId: this.idValue
      }
    );
  }
}
